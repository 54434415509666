import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
    // Recursive function to search through array and append new child data on node click
    deepReplace = (data, responseData) => {
        for (let i = 0; i < Object.keys(data).length; i++) {
            let child = data[Object.keys(data)[i]];

            if (child != null && typeof child === 'object') {
                if (child.hasOwnProperty('path')) {
                    if (child['path'] === this.path) {
                        child['isOpened'] = true;
                        child['children'] = responseData;
                        data[Object.keys(data)[i]] = child;

                        return data;
                    } else if (this.path.indexOf(data[Object.keys(data)[i]].path) > -1) {
                        if (data[Object.keys(data)[i]]['children']) {
                            data[Object.keys(data)[i]]['children'] = this.deepReplace(child['children'], responseData);
                        }
                    }
                }
            }
        }
        return data;
    };

    findNode(data, path) {
        let folders = path.split('/');
        let i = 0;
        let found = null;

        while (i < folders.length) {
            let currentFolder = folders.slice(0, i + 1).join('/');
            found = data.find((child) => child.path === currentFolder);

            // only check children if we're not in the last folder
            if (i + 1 < folders.length && found && found.children.length > 0) {
                return this.findNode(found.children, path);
            }

            i++;
        }

        return found;
    }

    async toggleChildren(rootElement, open) {
        let openIcon = rootElement.querySelector('.open-icon');
        if (open) {
            rootElement.classList.remove('open');
            openIcon.classList.add('fa-folder');
            openIcon.classList.remove('fa-folder-open');
        } else {
            rootElement.classList.add('open');
            openIcon.classList.remove('fa-folder');
            openIcon.classList.add('fa-folder-open');
        }
        let selectedChildren = open ? rootElement.querySelectorAll('li') : rootElement.querySelectorAll(':scope > li');
        selectedChildren.forEach((child) => {
            openIcon = child.querySelector('.open-icon');
            if (open) {
                child.classList.add('hidden');
                openIcon && openIcon.classList.remove('fa-folder');
                openIcon && openIcon.classList.add('fa-folder-open');
            } else {
                child.classList.remove('hidden', 'open');
                openIcon && openIcon.classList.add('fa-folder');
                openIcon && openIcon.classList.remove('fa-folder-open');
            }
        });
    }
}
