import {Controller} from '@hotwired/stimulus';
window.jQuery = window.$ = require('jquery');

export default class extends Controller {
    static values = {
        options: Object,
    };

    table = null;

    connect() {
        if (!$.fn.DataTable.isDataTable(this.element)) {
            this.table = $(this.element).DataTable({
                ...this.defaultOptions,
                ...this.optionsValue,
            });
        }
    }

    get defaultOptions() {
        return {
            pagingType: 'simple',
            processing: true,
            searching: true,
            responsive: true,
            retrieve: true,
            pageLength: 50,
        };
    }
}
