window.showErrorMessage = function (message, duration = 2000) {
    if (message.trim() !== '') {
        window.dispatchEvent(
            new CustomEvent('showErrorMessage', {
                detail: {
                    message: message,
                    duration: duration,
                },
            })
        );
    }
};

window.showSuccessMessage = function (message, duration = 2000) {
    if (message.trim() !== '') {
        window.dispatchEvent(
            new CustomEvent('showSuccessMessage', {
                detail: {
                    message: message,
                    duration: duration,
                },
            })
        );
    }
};
